<template>
    <div v-if="value">
        <div class="model-box">
            <div class="b-title">
                <span v-if="version === 'v1'">一.</span>
                示范社区整体情况
            </div>
            <div class="title">1.示范社区访问用户数据情况</div>
            <div class="table-box">
                <ElemTable :isIndex="true" :expand="expand" color="#21c065" :columns="visit_column" :dataList="value.onlineCommunityVoList"></ElemTable>
            </div>
        </div>
        <div class="model-box">
            <div class="title">2.示范社区注册用户数据情况</div>
            <div class="table-box">
                <ElemTable :isIndex="true" :expand="expand" color="#21c065" :columns="registered_column" :dataList="value.onlineCommunityVoList"></ElemTable>
            </div>
        </div>
        <!-- <div class="model-box">
            <div class="title">3.示范社区“基层自治组织动员体系”建设推广情况</div>
            <div class="table-box">
                <ElemTable :isIndex="true" :expand="expand" color="#21c065" :columns="promotion_column" :dataList="value.onlineCommunityVoList"></ElemTable>
            </div>
        </div> -->
        <div class="model-box">
            <div class="title">3.示范社区信息发布数</div>
            <div class="table-box">
                <ElemTable :isIndex="true" :expand="expand" color="#21c065" :columns="message_column" :dataList="value.onlineCommunityVoList"></ElemTable>
            </div>
        </div>
        <div class="model-box">
            <div class="title">4.示范社区接受服务人次</div>
            <div class="table-box">
                <ElemTable :isIndex="true" :expand="expand" color="#21c065" :columns="serve_column" :dataList="value.onlineCommunityVoList"></ElemTable>
            </div>
        </div>
        <div class="model-box">
            <div class="title">5.示范社区参与活动人次</div>
            <div class="table-box">
                <ElemTable :isIndex="true" :expand="expand" color="#21c065" :columns="activity_column" :dataList="value.onlineCommunityVoList"></ElemTable>
            </div>
        </div>
        <div class="model-box">
            <div class="title">6.示范社区空白栏目情况</div>
            <div class="table-box">
                <ElemTable :isIndex="true" :expand="expand" color="#21c065" :columns="blank_column" :dataList="value.onlineCommunityVoList"></ElemTable>
            </div>
        </div>

        <div class="model-box" v-if="version === 'v1'">
            <div class="b-title">二.各街镇整体情况</div>
            <div class="title">1.各街镇访问用户数据情况</div>
            <div class="table-box">
                <ElemTable :isIndex="true" :expand="expand" color="#21c065" :columns="street_visit_column" :dataList="value.streetVoList"></ElemTable>
            </div>
        </div>
        <div class="model-box" v-if="version === 'v1'">
            <div class="title">2.各街镇注册用户数据情况</div>
            <div class="table-box">
                <ElemTable :isIndex="true" :expand="expand" color="#21c065" :columns="street_registered_column" :dataList="value.streetVoList"></ElemTable>
            </div>
        </div>
        <!-- <div class="model-box">
            <div class="title">2.各街镇“基层自治组织动员体系”建设推广情况</div>
            <div class="table-box">
                <ElemTable :isIndex="true" :expand="expand" color="#21c065" :columns="street_promotion_column" :dataList="value.streetVoList"></ElemTable>
            </div>
        </div> -->
        <div class="model-box" v-if="version === 'v1'">
            <div class="title">3.各街镇信息发布数</div>
            <div class="table-box">
                <ElemTable :isIndex="true" :expand="expand" color="#21c065" :columns="street_message_column" :dataList="value.streetVoList"></ElemTable>
            </div>
        </div>
        <div class="model-box" v-if="version === 'v1'">
            <div class="title">4.各街镇接受服务人次</div>
            <div class="table-box">
                <ElemTable :isIndex="true" :expand="expand" color="#21c065" :columns="street_serve_column" :dataList="value.streetVoList"></ElemTable>
            </div>
        </div>
        <div class="model-box" v-if="version === 'v1'">
            <div class="title">5.各街镇参与活动人次</div>
            <div class="table-box">
                <ElemTable :isIndex="true" :expand="expand" color="#21c065" :columns="street_activity_column" :dataList="value.streetVoList"></ElemTable>
            </div>
        </div>
        <!-- <div class="model-box">
            <div class="title">6.空白栏目情况</div>
            <div class="table-box">
                <ElemTable :isIndex="true" :expand="expand" color="red" :columns="street_blank_column" :dataList="value.streetVoList"></ElemTable>
            </div>
        </div> -->
    </div>
</template>

<script>
import ElemTable from "../../communitymobilization/components/ElemTable.vue"

export default {
    components: { ElemTable },

    data() {
        return {
            // 访问用户
            visit_column: [
                {
                    title: "街镇",
                    key: "streetName",
                    width: 200,
                },
                {
                    title: "社区",
                    key: "orgName",
                    width: 200,
                },
                {
                    title: "累计访问人数",
                    key: "statRegister",
                    highlight: true,
                    sort: "desc",
                    width: 150,
                    sum: true,
                },
                {
                    title: "本年新增访问人数",
                    key: "registerOfYear",
                    highlight: true,
                    width: 150,
                    sum: true,
                },
                {
                    title: "本期访问人数",
                    key: "addRegister",
                    highlight: true,
                    width: 150,
                    sum: true,
                },
            ],
            // 注册用户
            registered_column: [
                {
                    title: "街镇",
                    key: "streetName",
                    width: 200,
                },
                {
                    title: "社区",
                    key: "orgName",
                    width: 200,
                },
                {
                    title: "累计注册人数",
                    key: "userRegister",
                    highlight: true,
                    sort: "desc",
                    width: 150,
                    sum: true,
                },
                {
                    title: "本年新增注册人数",
                    key: "userRegisterOfYear",
                    highlight: true,
                    width: 150,
                    sum: true,
                },
                {
                    title: "本期新增注册人数",
                    key: "addUserRegister",
                    highlight: true,
                    width: 150,
                    sum: true,
                },
            ],
            // 推广情况
            // promotion_column: [
            //     {
            //         title: "街镇",
            //         key: "streetName",
            //         width: 200,
            //     },
            //     {
            //         title: "社区",
            //         key: "orgName",
            //         width: 200,
            //     },
            //     {
            //         title: "现入驻人数",
            //         key: "onlineHomeNum",
            //         highlight: true,
            //         sort: "desc",
            //         width: 150,
            //         sum: true,
            //     },
            //     {
            //         title: "本期新入驻人数",
            //         key: "addOnlineHomeNum",
            //         highlight: true,
            //         width: 150,
            //         sum: true,
            //     },
            // ],
            // 信息发布
            message_column: [
                {
                    title: "街镇",
                    key: "streetName",
                    width: 200,
                },
                {
                    title: "社区",
                    key: "orgName",
                    width: 200,
                },
                {
                    title: "累计发布数",
                    key: "statOrgPublishNum",
                    highlight: true,
                    sort: "desc",
                    width: 150,
                    sum: true,
                },
                {
                    title: "本年新增发布数",
                    key: "orgPublishNumOfYear",
                    highlight: true,
                    width: 150,
                    sum: true,
                },
                {
                    title: "本期新增发布数",
                    key: "addOrgPublishNum",
                    highlight: true,
                    width: 150,
                    sum: true,
                },
            ],
            // 服务人次
            serve_column: [
                {
                    title: "街镇",
                    key: "streetName",
                    width: 200,
                },
                {
                    title: "社区",
                    key: "orgName",
                    width: 200,
                },
                {
                    title: "累计接受服务人次",
                    key: "statViewNum",
                    highlight: true,
                    sort: "desc",
                    width: 150,
                    sum: true,
                },
                {
                    title: "本年新增接受服务人次",
                    key: "viewOfYear",
                    highlight: true,
                    width: 150,
                    sum: true,
                },
                {
                    title: "本期新增接受服务人次",
                    key: "addViewNum",
                    highlight: true,
                    width: 150,
                    sum: true,
                },
            ],
            // 参与活动人次
            activity_column: [
                {
                    title: "街镇",
                    key: "streetName",
                    width: 200,
                },
                {
                    title: "社区",
                    key: "orgName",
                    width: 200,
                },
                {
                    title: "累计参与活动人次",
                    key: "statJoinNum",
                    highlight: true,
                    sort: "desc",
                    width: 150,
                    sum: true,
                },
                {
                    title: "本年新增参与活动人次",
                    key: "joinOfYear",
                    highlight: true,
                    width: 150,
                    sum: true,
                },
                {
                    title: "本期新增参与活动人次",
                    key: "addJoinNum",
                    highlight: true,
                    width: 150,
                    sum: true,
                },
            ],
            // 空白栏目
            blank_column: [
                {
                    title: "街镇",
                    key: "streetName",
                    width: 150,
                },
                {
                    title: "社区",
                    key: "orgName",
                    width: 150,
                },
                {
                    title: "空白栏目清单",
                    highlight: true,
                    sort: (a, b) => {
                        if (/^<div.*>无<\/div>/.test(b)) return -1
                    },
                    width: 400,
                    render(h, v) {
                        return `<div style="color:${v.row.blankColumns?.length > 0 ? "red" : ""}">` + (v.row.blankColumns?.map(item => `<p>${item}</p>`).join("") || "无") + `</div>`
                    },
                },
            ],

            // 访问用户
            street_visit_column: [
                {
                    title: "街镇",
                    key: "streetName",
                    width: 200,
                },
                {
                    title: "累计访问人数",
                    key: "statRegister",
                    highlight: true,
                    sort: "desc",
                    width: 150,
                },
                {
                    title: "本年新增访问人数",
                    key: "registerOfYear",
                    highlight: true,
                    width: 150,
                },
                {
                    title: "本期新增访问人数",
                    key: "addRegister",
                    highlight: true,
                    width: 150,
                },
            ],
            // 注册用户
            street_registered_column: [
                {
                    title: "街镇",
                    key: "streetName",
                    width: 200,
                },
                {
                    title: "累计注册人数",
                    key: "userRegister",
                    highlight: true,
                    sort: "desc",
                    width: 150,
                },
                {
                    title: "本年新增注册人数",
                    key: "userRegisterOfYear",
                    highlight: true,
                    width: 150,
                },
                {
                    title: "本期新增人数",
                    key: "addUserRegister",
                    highlight: true,
                    width: 150,
                },
            ],
            // 推广情况
            // street_promotion_column: [
            //     {
            //         title: "街镇",
            //         key: "streetName",
            //         width: 200,
            //     },
            //     {
            //         title: "现入驻人数",
            //         key: "onlineHomeNum",
            //         highlight: true,
            //         sort: "desc",
            //         width: 150,
            //     },
            //     {
            //         title: "本期新入驻人数",
            //         key: "addOnlineHomeNum",
            //         highlight: true,
            //         width: 150,
            //     },
            // ],
            // 信息发布
            street_message_column: [
                {
                    title: "街镇",
                    key: "streetName",
                    width: 200,
                },
                {
                    title: "累计发布数",
                    key: "statOrgPublishNum",
                    highlight: true,
                    sort: "desc",
                    width: 150,
                },
                {
                    title: "本年新增发布数",
                    key: "orgPublishNumOfYear",
                    highlight: true,
                    width: 150,
                },
                {
                    title: "本期新增发布数",
                    key: "addOrgPublishNum",
                    highlight: true,
                    width: 150,
                },
            ],
            // 服务人次
            street_serve_column: [
                {
                    title: "街镇",
                    key: "streetName",
                    width: 200,
                },
                {
                    title: "累计接受服务人次",
                    key: "statViewNum",
                    highlight: true,
                    sort: "desc",
                    width: 150,
                },
                {
                    title: "本年新增接受服务人次",
                    key: "viewOfYear",
                    highlight: true,
                    width: 150,
                },
                {
                    title: "本期新增接受服务人次",
                    key: "addViewNum",
                    highlight: true,
                    width: 150,
                },
            ],
            // 参与活动人次
            street_activity_column: [
                {
                    title: "街镇",
                    key: "streetName",
                    width: 200,
                },
                {
                    title: "累计参与活动人次",
                    key: "statJoinNum",
                    highlight: true,
                    sort: "desc",
                    width: 150,
                },
                {
                    title: "本年新增参与活动人次",
                    key: "joinOfYear",
                    highlight: true,
                    width: 150,
                },
                {
                    title: "本期新增参与活动人次",
                    key: "addJoinNum",
                    highlight: true,
                    width: 150,
                },
            ],
            // 空白栏目情况
            street_blank_column: [
                {
                    title: "街镇",
                    key: "streetName",
                    width: 200,
                },
                {
                    title: "总社区数",
                    key: "communityNum",
                    width: 200,
                },
                {
                    title: "空白栏目社区数",
                    highlight: true,
                    sort: "desc",
                    width: 200,
                    render: (h, v) => v.row.blackCommunityNum || 0,
                },
            ],
        }
    },

    props: {
        value: Object,
        expand: {
            type: Boolean,
            default: false,
        },
        version: {
            type: String,
            default: "v1",
        },
    },
}
</script>

<style></style>
